@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html,
body,
#root,
.app,
.content {
  height: 100%;
  /* width: 100%; */
  font-family: "Open Sans", sans-serif;
  margin: 0
}

.content {
  flex-basis: 100%;
  overflow-y: scroll;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}

/* .main-section {
  padding: 1rem;
} */

input, select, textarea {
  padding: 0.5rem;
  background: #EAECF0FF;
  border: none;
  font-size: 14px;
  border-radius: 4px;
}

label {
  font-size: 14px;
}

.patientFormFields {
  display: grid;
  margin-right: 12px;
}

input[type=number] {
  -moz-appearance: textfield; /* For Firefox */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none; /* For Chrome, Safari, Edge */
  margin: 0; /* Remove margin */
}

.ps-active, .ps-active > a{
  background-color: rgb(255, 185, 29)!important;
}

.css-1xc3v61-indicatorContainer {
  padding: "1rem!important"
}

/* Hide the clear button for Chrome, Edge, and Safari */
input[type="date"]::-webkit-clear-button {
  display: none;
}

/* Hide the clear button for Firefox */
input[type="date"]::-moz-clear {
  display: none;
}

/* Remove the clear button for Edge */
input[type="date"]::-ms-clear {
  display: none;
}

.react-datepicker-popper {
  z-index: 1000 !important;
}

.react-datepicker__input-container input {
  width: 67%; /* this makes the input take the full width of the wrapper */
  max-width: 300px; /* optional: set a max-width to prevent excessive stretching */
}